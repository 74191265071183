<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Solicitante"
                    :valueInput="values.seller"
                    :rule="rules.required"
                    :items="serverItems.sellers"
                    :errorMessages="serverErrors.seller"
                    @valueChange="(v) => {values.seller = v; delete serverErrors.seller;delete serverErrors.client;delete serverErrors.seller}"
                    data-cy="seller-input"
                    :disabled="edit"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2" cols="12" sm="6">
                <autocompleteOneForm
                    label="Orden de trabajo"
                    :loading="loadingSaleOrders"
                    :valueInput="values.sale_order_id"
                    :rule="rules.required"
                    :items="serverItems.saleOrders"
                    :errorMessages="serverErrors.sale_order_id"
                    @valueChange="(v) => {values.sale_order_id = v; delete serverErrors.sale_order_id;delete serverErrors.client;delete serverErrors.sale_order_id}"
                    data-cy="sale_order_id-input"
                    
                ></autocompleteOneForm>
            </v-col>

             <v-col  class="py-2" cols="12" sm="6">
                <autocompleteOneForm
                    label="Proveedor"
                    :loading="loadingSuppliers"
                    :valueInput="values.supplier_id"
                    :rule="rules.required"
                    :items="serverItems.suppliers"
                    :errorMessages="serverErrors.supplier_id"
                    @valueChange="(v) => {values.supplier_id = v; clear(); getProducts(); delete serverErrors.supplier_id;delete serverErrors.client;delete serverErrors.supplier_id}"
                    data-cy="supplier_id-input"
                    
                ></autocompleteOneForm>
               
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2 mb-5">
                <datePickerForm
                    label="Fecha"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                <p class="mb-0 pb-0">Materias primas</p>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Materia prima"
                    :loading="loadingProducts"
                    :valueInput="productSelected"
                    :rule="[]"
                    :items="serverItems.products"
                    :returnObject="true"
                    @valueChange="(v) => {productSelected = v;}"
                    data-cy="productSelected-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right">
                <v-btn
                text
                @click="addProduct()"
                class="font-weight-bold"
                data-cy="addProduct-btn"
                >Agregar</v-btn>
                <!-- <secondary-button data-cy="cancel-btn" :props="addBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button> -->
            </v-col>
        </v-row>
        

        <!-- table -->
        <v-row>
            <v-col class="py-2">
                <table-items data-cy="table-items" :props="tableProps" @methodHandler="method_handler" :items="values.commodities">
                    
                    <template v-slot:item.quantity="props" >

                        <div class="table-item">
                            <textFieldForm 
                                @valueChange="(v) => {props.item.quantity = v; delete serverErrors.quotation_details}" 
                                :valueInput="props.item.quantity" 
                                :rule="rules.cost" 
                                :disabled="props.item.disabled"
                                data-cy="table-quantity-input"
                                
                            ></textFieldForm>
                        </div>
                        
                    </template>

                   

                    
                </table-items>
                
            </v-col>
            
        </v-row>
        <span class="red--text caption" v-if="serverErrors[`quotation_details`]">{{serverErrors[`quotation_details`]}}</span>
      
      

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            showProductForm:false,
            loadingSaleOrders: false,
            loadingSuppliers: false,
            loadingProducts: false,
            productSelected: "",
           
           
            actions: [
				
				{
					name: 'Borrar',
					icon: {
						color: 'primary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItem',
                    dataCy: "delete-link"
				},
			],
            tableProps: {
				headers: [
                     {
						text: 'Materia prima',
						align: 'left break-words',
						value: 'commodity',
                        
						class: 'table-b-border black--text',
                        sortable: false,
					},
					{
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                    {
						text: 'Unidad',
						align: 'center',
						value: 'unit',
                        width: "20%",
						sortable: false,
						class: 'table-b-border black--text'
					},
                   
					{
						text: '',
						align: 'right',
						sortable: false,
						value: 'actions',
						width: '10%',
						class: 'table-b-border black--text'
					}
				],
				items: [],
				search: '',
				loading: false
			},
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
                quantity: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
				
				
				
            },

            serverItems:{
                sellers: [
                    { text: 'Aram Set', value: 'Aram Set' },
                    { text: 'Arianna Ramírez', value: 'Arianna Ramírez' },
                    { text: 'Armando Martínez', value: 'Armando Martínez' },
                    { text: 'Carlos Alberto', value: 'Carlos Alberto' },
                    { text: 'Eduardo López', value: 'Eduardo López' },
                    { text: 'Hector Ugalde', value: 'Hector Ugalde' },
                    { text: 'Israel Cortés', value: 'Israel Cortés' },
                    { text: 'Pedro Arellano', value: 'Pedro Arellano' },
                    { text: 'Mario Mercado Ugalde', value: 'Mario Mercado Ugalde' },
                    { text: 'Roberto Guadiana', value: 'Roberto Guadiana' },
                    { text: 'Roberto Mercado', value: 'Roberto Mercado' },
                    // { text: 'Roberto Mercado Ugalde', value: 'Roberto Mercado Ugalde' },
                ],
                saleOrders: [],
                suppliers: [],
                products: []

            }

            

          
        }
    },
   
    mounted(){
        this.getSaleOrders();
        this.getSuppliers();
        this.getProducts();
        this.loadProducts();
    },
    methods: {
        method_handler(object){
			this[object.methodName](object.parameters)
		},
        clear()
        {
            this.values.commodities = [];
            this.serverItems.products = [];
        },
        getSaleOrders()
        {
            this.loadingSaleOrders = true;
            this.$api.saleOrder.getSelect()
                .then((resp) => {
                    this.serverItems.saleOrders = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSaleOrders = false;
                })
            ;
        },
        getSuppliers()
        {
            this.loadingSuppliers = true;
            this.$api.supplier.getSelect()
                .then((resp) => {
                    this.serverItems.suppliers = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingSuppliers = false;
                })
            ;
        },

        getProducts()
        {
            if(this.values.supplier_id == undefined || this.values.supplier_id == "")
                return;

            this.loadingProducts = true;
            this.$api.commodity.bySupplier(this.values.supplier_id)
                .then((resp) => {
                    this.serverItems.products = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingProducts = false;
                })
            ;
        },

        loadProducts()
        {
            this.values.commodities = this.values.commodities.map((item,i) => {
                // x['parameters'] = { id: x.slug, name: x.sku, index: i};
                
              
               
                let newProd = {
                    actions: JSON.parse(JSON.stringify(this.actions)),
                    parameters: { id: item.commodity_variant_id, name: item.product_name, index: 99},
                    id: item.commodity_variant_id,
                    commodity: `${item.commodity}|${item.size}|${item.color}`,
                    quantity : item.quantity,
                    unit: item.unit
                }
                return newProd;
            })
        },

        addProduct()
        {
            if(!("value" in this.productSelected))
                return;

            let found = this.values.commodities.find(fItem => fItem.id == this.productSelected.value)
            if(found != undefined)
                return;

            let item = this.productSelected;
            const fullname = item.text.split("|");
            const unit = fullname[fullname.length - 1];
            fullname.splice( fullname.length - 1, 1);
            console.log(fullname)
            const name = fullname.join("|");

            let newProd = {
                actions: JSON.parse(JSON.stringify(this.actions)),
                parameters: { id: item.value, name: name, index: 99},
                id: item.value,
                commodity: name,
                unit: unit,
                quantity : 1,   //item.quantity,
               
            }

            this.values.commodities.push(newProd);
        },

        deleteItem(item)
        {
            let index = this.values.commodities.findIndex(prod => prod.id == item.id);

            console.log(index, item);
            this.values.commodities.splice(index, 1);
        },


    }
}
</script>

<style>

</style>